<template>
  <div class="g col-12 col-lg-6 c">
    <br>
    <div class="card">
        <div class="card-body">
            <div class="col-12 col-lg-6 c">
                <div class="form-group">
                <label for="">تغيير الكل الى</label>
                <select class="form-control" v-model="all_type">
                    <option value="">-- اختر --</option>
                    <option value="5">تأخير بدون عذر</option>
                    <option value="3">تأخير بعذر</option>
                </select>
                </div>
                <div class="form-group">
                <label for="">ساعات التأخر</label>
                <input type="number"
                    class="form-control" v-model="all_h" placeholder="ساعة">
                </div>
                <div class="form-group">
                <label for="">دقائق التأخر</label>
                <input type="number"
                    class="form-control" v-model="all_m" placeholder="دقيقة">
                </div>
                <div class="col-12 g text-center">
                    <button class="btn btn-danger btn-sm" @click="changeAll()">تغيير الكل</button>
                </div>
                <hr>
                <div class="col-12 g text-center">
                    <button class="btn btn-success" @click="addNow()">اضافة التأخر الآن</button>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="col-12 table-responsive">
                <table class="table table-sm table-hover table-striped table-bordered">
                    <thead>
                        <th>

                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" @click="all()" v-model="s" value="true">
                                    اختيار الكل
                                  </label>
                                </div>
                        </th>
                        <th>
                            المعلم
                        </th>
                        <th>
                            النوع
                        </th>
                        <th>
                            المدة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="teacher in teachers" :key="teacher.id">
                            <td>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="allowed" :value="teacher.id">
                                    اختيار
                                  </label>
                                </div>
                            </td>
                            <td>
                                {{ teacher.name }}
                            </td>
                            <tdd>
                                <select v-model="teacher.type" style="border:none; width: 100%" v-if="allowed.includes(teacher.id)">
                                    <option value="5">تأخير بدون عذر</option>
                                    <option value="3">تأخير بعذر</option>
                                </select>
                            </tdd>
                            <td>
                               <span style="display: inline-block" v-if="allowed.includes(teacher.id)">
                                    <input type="number" style="border:none; width: 100px" v-model="teacher.h" placeholder="ساعة">
                               </span>
                               <span style="display: inline-block" v-if="allowed.includes(teacher.id)">
                                    <input type="number" style="border:none; width: 100px" v-model="teacher.m" placeholder="دقيقة">
                               </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            teachers: [],
            loading: false,
            all_type: "",
            all_h: "",
            all_m: "",
            allowed: [],
            s: false
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      setTimeout(() => {
        $("#date").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='teachers'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='teachers']`).length){
              clearInterval(i1)
              g.teachers = JSON.parse($(`.get-local-storage-value[name='teachers']`).val())
              var v = []
              g.teachers.forEach(element => {
                element.type = "5"
                element.h = ""
                element.m = ""
                v.push(element)
              });
              g.teachers = v
            }
        }, 10);
      }, 500);
    },
    methods: {
        refresh(){
          this.teachers = JSON.parse(JSON.stringify(this.teachers))
        },
        changeAll(){
            var g = this, arr = [];
            g.teachers.forEach(function(a){
                a.type = g.all_type
                a.h = g. all_h
                a.m = g.all_m
                arr.push(a)
            })
            g.teachers = arr
        },
        deleteme(id){
            var g = this, arr = [];
            g.teachers.forEach(function(a){
                if(a.id != id){
                    arr.push(a)
                }
            })
            g.teachers = arr
            $(".table-responsive").fadeOut(0)
                $(".table-responsive").fadeIn('slow')
        },
        all(){
            this.allowed = !this.s ? this.teachers.map((x)=>{return x.id}) : []
        },
        addNow(){
            var g = this;
            var naqar_set = function(t, value){
                return new Promise((resolveOuter) => {
                    $("body").append(`<textarea class='save-me-to-local-storage' name='${t}'>${value}</textarea>`)
                    var i111 = setInterval(function(){
                        if(!$(".save-me-to-local-storage[name='"+t+"']").length){
                            clearInterval(i111)
                            resolveOuter()
                        }
                    })
                });
            }
            var v = []
              g.teachers.forEach(element => {
                element.type = element.type ?? "-99"
                element.h = element.h ?? 0
                element.m = element.m ?? 0
                if(g.allowed.includes(element.id)){
                    v.push(element)
                }
              });
              g.teachers = v
            naqar_set("addlate", JSON.stringify(g.teachers)).then(function(){
                alert("قم بالذهاب الآن لصفحة 'شؤون المعلمين' في نور.")
            })
        }
    }
}
</script>

<style>
body, html{
    background: #ddd !important;
}
</style>